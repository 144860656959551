const login = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
        max: 'Email should not be greater than 100 characters.',
    },
    password: {
        required: 'Password field is required.',
        invalid: 'Please enter a valid password.',
        min: 'Password must be at least 8 characters long.',
    },
    verificationCode: {
        required: 'OTP field is required.',
        min: 'OTP must have 6 characters.',
    },
    fullName: {
        required: 'Full name field is required.',
        min: 'Full name must be at least 2 characters.',
        max: 'Full name should not be greater than 100 characters.',
    },
    phoneNumber: {
        required: 'Mobile number field is required.',
        matches: 'Please enter a valid mobile number. e.g 03xxxxxxxxx',
    },
    businessName: {
        required: 'Business name field is required.',
        min: 'Business name must be at least 1 character.',
        max: 'Business name should not be greater than 100 characters.',
    },
}

const forgot = {
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
        max: 'Email should not be greater than 100 characters.',
    },
}
const resetPassword = {
    password: {
        required: 'New password field is required.',
        min: 'New password must be a minimum of 8 characters.',
        max: 'New password field must be between 8 to 50 characters.',
        mismatch: 'New password and confirm password fields do not match.',
        uppercaseNumber:
            'Your password must contain at least one capital letter (A-Z) and one number (0-9).',
    },
    confirmPassword: {
        required: 'Confirm password field is required.',
    },
}
const users = {
    refCode: {
        required: 'Ref code field is required.',
        length: 'Ref code must be between 1 and 100 characters.',
        min: 'Ref code must be at least 2 characters.',
        max: 'Ref code should not be greater than 50 characters.',
    },
    fullName: {
        required: 'Name field is required.',
        length: 'Name must be between 1 and 100 characters.',
        min: 'Name must be at least 2 characters.',
        max: 'Name should not be greater than 100 characters.',
    },
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
        max: 'Email should not be greater than 100 characters.',
    },
    countries: {
        required: 'Country field is required.',
    },
}
const contacts = {
    refCode: {
        required: 'Contact ref field is required.',
        min: 'Contact ref must be at least 2 characters.',
        max: 'Contact ref should not be greater than 50 characters.',
    },
    fullName: {
        required: 'Contact name field is required.',
        min: 'Contact name must be at least 2 characters.',
        max: 'Contact name should not be greater than 100 characters.',
    },
    secondaryName: {
        min: 'Secondary name must be at least 2 characters.',
        max: 'Secondary name should not be greater than 100 characters.',
    },
    email: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
        max: 'Email should not be greater than 100 characters.',
    },
    companyName: {
        required: 'Company name field is required.',
        min: 'Company name must be at least 2 characters.',
        max: 'Company name should not be greater than 100 characters.',
    },
    address1: {
        required: 'Address field is required.',
        min: 'Address must be at least 2 characters.',
        max: 'Address should not be greater than 100 characters.',
    },
    address2: {
        min: 'Address 2 must be at least 2 characters.',
        max: 'Address 2 should not be greater than 100 characters.',
    },
    address3: {
        min: 'Address 3 must be at least 2 characters.',
        max: 'Address 3 should not be greater than 100 characters.',
    },
    currencyId: {
        required: 'Currency field is required.',
    },
    phoneNumber: {
        required: 'Phone number field is required.',
        valid: 'Please enter a valid phone number.',
    },
    faxNumber: {
        required: 'Fax number field is required.',
        valid: 'Please enter a valid phone number.',
    },
    contactTypeId: {
        required: 'Contact type field is required.',
    },
    countryId: {
        required: 'Country field is required.',
    },
}

const projects = {
    refCode: {
        required: 'Project ref no. field is required.',
    },
    name: {
        required: 'Project name field is required.',
        min: 'Project name must be at least 1 character.',
        max: 'Project name should not be more than 100 characters.',
    },
    address1: {
        required: 'Address field is required.',
        min: 'Address must be at least 2 characters.',
        max: 'Address should not be more than 100 characters.',
    },
    address2: {
        min: 'Address 2 must be at least 2 characters.',
        max: 'Address 2 should not be more than 100 characters.',
    },
    address3: {
        min: 'Address 3 must be at least 2 characters.',
        max: 'Address 3 should not be more than 100 characters.',
    },
    countryId: {
        required: 'Country field is required.',
    },
    currencyId: {
        required: 'Currency field is required.',
    },
    repId: {
        required: 'Rep field is required.',
    },
    estimatorId: {
        required: 'Estimator field is required.',
    },
    createdAt: {
        required: 'Creation date field is required.',
    },
    chaseAt: {
        required: 'Chase date field is required.',
    },
    buildAt: {
        required: 'Build date field is required.',
    },
    contactRefCode: {
        required: 'Contact ref field is required.',
        min: 'Contact ref must be at least 1 character.',
        max: 'Contact ref should not be more than 100 characters.',
    },
    contactFullName: {
        required: 'Contact name field is required.',
        min: 'Contact name must be at least 1 character.',
        max: 'Contact name should not be more than 100 characters.',
    },
    contactCompanyName: {
        required: 'Company name field is required.',
        min: 'Company name must be at least 1 character.',
        max: 'Company name should not be more than 100 characters.',
    },
    contactAddress1: {
        required: 'Contact address field is required.',
        min: 'Contact address needs to have at least 2 characters.',
        max: 'Contact address should not be more than 100 characters.',
    },
    contactAddress2: {
        min: 'Contact address 2 needs to have at least 2 characters.',
        max: 'Contact address 2 should not be more than 100 characters.',
    },
    contactAddress3: {
        min: 'Contact address 3 needs to have at least 2 characters.',
        max: 'Contact address 3 should not be more than 100 characters.',
    },
    contactCountryId: {
        required: 'Contact country field is required.',
    },
    contactCurrencyId: {
        required: 'Contact currency field is required.',
    },
    contactPhoneNumber: {
        required: 'Contact phone number field is required.',
        matches: 'Please enter a valid phone number e.g (123)456-7890',
    },
    contactFaxNumber: {
        matches: 'Please enter a valid fax number e.g (123)456-7890',
    },
    contactEmail: {
        required: 'Email field is required.',
        invalid: 'Please enter a valid email address.',
        max: 'Email must not be greater then 100 characters',
    },
    contactTypeId: {
        required: 'Contact type field is required.',
    },
    currencyRate: {
        required: 'Currency rate field is required.',
        min: 'Currency rate minimum value is 0.01.',
        invalid: 'Invalid value for currency rate.',
    },
    currencyOverride: {
        required: 'Currency override field is required.',
        min: 'Currency override minimum value is 1.',
        invalid: 'Invalid value for currency override.',
    },
    discount: {
        min: 'Discount minimum value is 1.',
        max: 'Discount maximum value is 99.9.',
    },
}

const products = {
    productStandard: {
        required: 'Product standard field is required.',
        min: 'Product standard must be at least 2 characters.',
        max: 'Product standard should not be more than 100 characters.',
        invalid: 'Invalid value for product standard.',
    },
    refCode: {
        required: 'Product ref. field is required.',
        min: 'Product ref. must be at least 2 characters.',
        max: 'Product ref. should not be more than 100 characters.',
        invalid: 'Invalid value for product ref.',
    },
    externalRefCode: {
        required: 'External product ref. is required field.',
        min: 'External product ref. must be at least 2 characters.',
        max: 'External product ref. should not be more than 100 characters.',
        invalid: 'Invalid value for external product ref.',
    },
    name: {
        required: 'Product name field is required.',
        min: 'Product name must be at least 2 characters.',
        max: 'Product name should not be more than 350 characters.',
        invalid: 'Invalid value for product name.',
    },
    productTypeId: {
        required: 'Product type field is required.',
        invalid: 'Invalid value for Product type',
    },
    modelNumber: {
        required: 'Model number field is required.',
        max: 'Model number should not be more than 100 characters.',
        // matches: 'Please enter a valid phone number e.g (123)456-7890',
    },
    finishId: {
        required: 'Finish field is required.',
        invalid: 'Invalid value for finish.',
    },
    fireRatingId: {
        required: 'Fire rating field is required.',
        invalid: 'Invalid value for fire rating.',
    },
    manufacturerId: {
        required: 'Manufacturer field is required.',
        invalid: 'Invalid value for manufacturer.',
    },
    supplierId: {
        required: 'Supplier field is required.',
        invalid: 'Invalid value for supplier.',
    },
    brandId: {
        required: 'Brand field is required.',
        invalid: 'Invalid value for brand.',
    },
    sellUnitId: {
        required: 'Sell unit field is required.',
        invalid: 'Invalid value for sell unit.',
    },
    costPrice: {
        required: 'Cost price field is required.',
        min: 'Cost price min value is 0.',
        invalid: 'Invalid value for cost price.',
        max: 'Cost price field should not be greater than 10 digits.',
    },
    markup: {
        required: 'Markup field is required.',
        min: 'Markup min value is 0.',
        invalid: 'Invalid value for markup.',
    },
    sellPrice: {
        required: 'Selling price field is required.',
        min: 'Selling price min value is 0.',
        invalid: 'Invalid value for selling price.',
        max: 'Sell price field should not be greater than 10 digits.',
    },
    minSellingPrice: {
        required: 'Min selling price field is required.',
        min: 'Min selling price min value is 0.01.',
        invalid: 'Invalid value for min selling price.',
        max: 'Min selling price field should not be greater than 10 digits.',
    },
    actualSellingPrice: {
        required: 'Actual sell price field is required.',
        min: 'Actual sell price min value is 0.01.',
        invalid: 'Invalid value for actual sell price.',
    },
    productTypeDiscount: {
        min: 'Product type discount min value is 0.',
        max: 'Product type discount max value is 99.9.',
        invalid: 'Invalid value for product type discount.',
    },
    grossProfitMargin: {
        required: 'Gross profit margin field is required.',
        invalid: 'Invalid value for gross profit margin.',
    },
    moreInfo: {
        longDesc: {
            required: 'Long description field is required.',
            max: 'Long description should not be more than 1000 words.',
            invalid: 'Invalid value for long description.',
        },
        altDesc: {
            required: 'Alt. description field is required.',
            max: 'Alt. description should not be more than 500 words.',
            invalid: 'Invalid value for alt. description.',
        },
    },
    files: {
        productImage: {
            required: 'Product image field is required.',
            invalid: 'Invalid value for product image.',
        },
        catalogueImage: {
            required: 'Catalogue image field is required.',
            invalid: 'Invalid value for catalogue image.',
        },
        specificationImage: {
            required: 'technical data sheet field is required.',
            invalid: 'Invalid value for technical data sheet.',
        },
        certOfConformity: {
            required: 'Cert of conformity field is required.',
            invalid: 'Invalid value for cert of conformity.',
        },
        testCertificate: {
            required: 'Test certificate field is required.',
            invalid: 'Invalid value for Test certificate',
        },
        doorDiagram: {
            required: 'Door diag. extra info field is required.',
            invalid: 'Invalid value for door diag. extra info.',
        },
    },
    webLink: {
        required: 'Web link field is required.',
        min: 'Web link must be at least 2 characters.',
        invalid: 'Invalid value for web link.',
    },
    quantity: {
        required: 'Quantity field is required.',
        min: 'Quantity min value is 0.1 .',
        typeError: 'Quantity field must be a number.',
        max: 'Quantity field should not be greater than 7 digits.',
    },
    searchTags: {
        required: 'Search tags field is required.',
    },
}
const configuration = {
    currencyId: {
        required: 'Default currency field is required.',
    },
    decimalPlaces: {
        required: 'Decimal places field is required.',
        min: 'Decimal places must be at least value 2. ',
        max: 'Decimal places must not be greater than 5.',
    },
    projectRefCodePrefix: {
        required: 'Reference code is required.',
        min: 'Reference code must be at least 2 characters.',
        max: 'Reference code should not be greater than 100 characters.',
    },
    headerId: {
        required: 'Default project report header is required.',
    },
    footerId: {
        required: 'Default project report footer is required.',
    },
    projectAccessAge: {
        required: 'Project access age field is required.',
        min: 'Project access must be at least 1 day.',
        max: 'Project access age must not be greater than 10 digits.',
    },
    downloadLimit: {
        required: 'Daily download limit field is required',
        max: 'Daily download limit must not be greater than 1000',
    },
    notifyEmail: {
        required: 'Notify email field is required.',
        invalid: 'Please enter valid notify email.',
        max: 'Notify email should not be greater than 100 characters.',
    },
    companyProfile: {
        required: 'Company profile is required.',
    },
    specialProductsPrefix: {
        required: 'Special product ref code prefix field is required',
        min: 'Special product ref code prefix must be at least 1 characters.',
        max: 'Special product ref code prefix should not be greater than 100 characters.',
    },
    specialProductsPostfix: {
        required: 'Special product ref code postfix field required.',
        min: 'Special product ref code postfix must be at least 1 characters.',
        max: 'Special product ref code postfix should not be greater than 100 characters.',
    },
    beginText: {
        required: 'Begin text field is required.',
    },
    endText: {
        required: 'End text field is required.',
    },
}
const hardwareSet = {
    refCode: {
        required: 'Hardware set ref field is required.',
        min: 'Hardware set ref must be at least 2 characters.',
        max: 'Hardware set ref should not be greater than 100 characters.',
    },
    name: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title should not be greater than 100 characters.',
    },
    description: {
        min: 'Details must be at least 2 characters.',
        max: 'Details should not be greater than 1000 characters.',
    },
    standard: {
        required: 'Standard field is required.',
    },
    notes: {
        min: 'Notes must be at least 2 characters.',
        max: 'Notes should not be greater than 1000 characters.',
    },
}
const hardwareSetProject = {
    projectVersionId: {
        required: 'Project field is required.',
    },
    hardwareSetId: {
        required: 'Hardware set field is required.',
    },
    refCode: {
        required: 'Hardware set ref field is required.',
    },
}
const houseTypes = {
    refCode: {
        required: 'Ref code field is required.',
        min: 'Ref code must be atleast 2 characters.',
        max: 'Ref code should not be greater than 100.',
    },
    name: {
        required: 'Title field is required',
        min: 'Title must be at least 2 characters.',
        max: 'Title should not be greater than 100 characters.',
    },
    description: {
        max: 'Details must not be greater than 1000 characters',
    },
}
const projectHardwareSetSpecs = {
    Area: {
        max: 'Area must not be greater than 100 characters',
    },
    Floor: {
        max: 'Floor must not be greater than 100 characters',
    },
    Info1: {
        max: 'Info 1 must not be greater than 100 characters',
    },
    Info2: {
        max: 'Info 2 must not be greater than 100 characters',
    },
    OurDoorRef: {
        max: 'Our door ref must not be greater than 100 characters',
    },
    extRef: {
        max: 'Ext ref must not be greater than 100 characters',
    },
    longSetInfo: {
        max: 'Long set info must not be greater than 100 characters',
    },
    acoustic: {
        max: 'Acoustic must not be greater than 100 characters',
    },
    accessControl: {
        max: 'Access control must not be greater than 100 characters',
    },
    rebate: {
        max: 'Rebate must not be greater than 100 characters',
    },
    keyedAlike: {
        max: 'keyed alike must not be greater than 100 characters',
    },
    masterKey: {
        max: 'Master key must not be greater than 100 characters',
    },
    subMasterKey: {
        max: 'Sub master key must not be greater than 100 characters',
    },
    numberOfKeys: {
        max: 'Number of keys must not be greater than 100 characters',
    },
    width: {
        max: 'Width must not be greater than 100 characters',
    },
    height: {
        max: 'Height must not be greater than 100 characters',
    },
    thickness: {
        max: 'Thickness must not be greater than 100 characters',
    },
    fireRating: {
        max: 'Fire rating must not be greater than 100 characters',
    },
    leaf: {
        max: 'Leaf must not be greater than 100 characters',
    },
    handing: {
        max: 'Handing must not be greater than 100 characters',
    },
    doorType: {
        max: 'Door type must not be greater than 100 characters',
    },
    material: {
        max: 'Material must not be greater than 100 characters',
    },
    doorWeight: {
        max: 'Door weight must not be greater than 100 characters',
    },
    swing: {
        max: 'Swing must not be greater than 100 characters',
    },
}
const projectVersion = {
    versionNumber: {
        required: 'Revision number field is required.',
    },
    versionName: {
        required: 'Revision title field is required.',
        min: 'Revision title must be at least 2 characters.',
        max: 'Revision title should not be greater than 100 characters.',
    },
    description: {
        max: 'Revision description must not be greater than 350 characters',
    },
}
const houseTypePlot = {
    reference: {
        required: 'Plot reference field is required.',
        min: 'Plot reference must be atleast 2 characters.',
        max: 'Plot reference should not be greater than 100.',
    },
    description: {
        required: 'Description field is required.',
        min: 'Description must be at least 1 characters.',
        max: 'Description should not be greater than 100 characters.',
    },
    blockLevel: {
        required: 'Block level field is required.',
        min: 'Block level must be atleast 1 digit.',
        max: 'Block level must not be greater than 1000 digits.',
    },
}
const houseTypeHardwareSets = {
    quantity: {
        required: 'Quantity field is required.',
        min: 'Quantity field value  must be atleast 1.',
        max: 'Quantity field should not be greater than 7 digits.',
    },
    hardwareSetQty: {
        required: 'Quantity field is required.',
        min: 'Quantity field value  must be atleast 0.1.',
        max: 'Quantity field should not be greater than 7 digits.',
    },
    description: {
        required: 'Description field is required.',
        min: 'Description must be at least 1 characters.',
        max: 'Description should not be greater than 100 characters.',
    },
    blockLevel: {
        required: 'Block level is required.',
        min: 'Block level must be atleast 1 digit.',
        max: 'Block level must not be greater than 1000 digits.',
    },
}
// houseTypeHardwareSets
const currencies = {
    refCode: {
        required: 'Currency code field is required.',
        min: 'Currency code must be at least 2 characters.',
        max: 'Currency code should not be greater than 10 characters.',
    },
    name: {
        required: 'Currency name field is required.',
        min: 'Currency name must be at least 2 characters.',
        max: 'Currency name should not be greater than 100 characters.',
    },
    countryId: {
        required: 'Territory field is required.',
    },
    rate: {
        required: 'Rate field is required.',
    },
    override: {
        required: 'Overide field is required.',
        max: 'Override cannot be greater than 100.',
    },
}
const contents = {
    content: {
        required: 'Content field is required.',
        min: 'Content must be at least 2 characters.',
    },
    name: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title should not be greater than 100 characters.',
    },
    type: {
        required: 'Type field is required.',
    },
    showDate: {
        required: 'Date field is required.',
    },
    showLogo: {
        required: 'Logo field is required.',
    },
    showPageNumber: {
        required: 'Page number field is required.',
    },
}
const attribute = {
    attributeRefCode: {
        required: 'Ref code field is required.',
        max: 'Ref code should not be greater than 50 characters.',
        min: 'Ref code must be at least 2 characters.',
    },
    attributeValue: {
        required: 'Title field is required.',
        min: 'Title must be at least 2 characters.',
        max: 'Title should not be greater than 100 characters.',
    },
    secondAttributeValue: {
        min: 'Title must be at least 2 characters.',
        max: 'Title should not be greater than 100 characters.',
    },
}
const doorValidations = {
    reference: {
        required: 'Door Reference field is required.',
        min: 'Door Reference must be atleast 2 characters.',
        max: 'Door Reference should not be greater than 100.',
    },
    sequence: {
        min: 'Door sequence must be atleast 1 digit.',
        max: 'Door sequence must not be greater than 10 digits.',
    },
    // packingDate: {
    //     required: "Packing Date must be 'MM/DD/YYYY'.",
    // },
    doorNumber: {
        min: 'Door number must be at least 1 characters.',
        max: 'Door number should not be greater than 100 characters.',
    },
    description: {
        min: 'Door Description must be at least 2 characters.',
        max: 'Door Description should not be greater than 1000 characters.',
    },
}
const addRange = {
    variable: {
        required: 'Door variable field is required.',
        min: 'Door variable must be atleast 1 digit',
        max: 'Door variable should not be greater than 7 digits',
    },
    doorNumber: {
        required: 'Door number field is required.',
        min: 'Door number must be at least 2.',
        max: 'Door number should not be greater than 3000.',
    },
}
const addQuantity = {
    doorNumber: {
        required: 'Door quantity field is required.',
        min: 'Door quantity must be at least 2.',
        max: 'Door quantity should not be greater than 10000.',
    },
}
export {
    login,
    forgot,
    resetPassword,
    projects,
    products,
    configuration,
    contacts,
    users,
    houseTypes,
    hardwareSet,
    currencies,
    contents,
    attribute,
    houseTypePlot,
    houseTypeHardwareSets,
    doorValidations,
    projectVersion,
    projectHardwareSetSpecs,
    hardwareSetProject,
    addRange,
    addQuantity,
}
